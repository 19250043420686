import * as React from 'react';
import { TakeAction } from '../components/about/TakeAction';
import { Section } from '../components/element/Section';
import { FindADoctor } from '../components/find-a-doctor/FindADoctor';
import { FindByName } from '../components/find-a-doctor/FindByName';
import { HealthCareConnect } from '../components/find-a-doctor/HealthCareConnect';

const FindADoctorPage = () => { 
  return (
    <>
      <Section flag={''} lastSection={true}>
        <FindADoctor />
      </Section>
      <Section flag={''} lastSection={true}>
        <HealthCareConnect />
      </Section>
      {/* <Section flag={''} lastSection={true}>
        <FindByName />
      </Section> */}
      <TakeAction flag={'--blue'}/>
    </>
  );
};

export default FindADoctorPage;