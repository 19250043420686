import { cnb } from "cnbuilder";
import * as React from "react";
import "./Section.scss";

type props = {
  children: React.ReactNode;
  lastSection: boolean;
  flag: string;
  id: string;
}

export const Section = ({children, lastSection= false, flag, id=""}: props) => {
  return (
    <section className={cnb("section", `section${flag}`, {["section--lastSection"]:lastSection})}>
      <div className="anchor" id={id}/>
      {children}
    </section>
  );
};