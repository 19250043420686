import * as React from 'react';
import image from "../../images/findDoc/Doctor@2x.png";
import "./FindADoctor.scss";

export const FindADoctor = () => {
  return (
  <>
    {/* <div className='nav-gap'/> */}
    <div className='findADoctor'>

      <div className='findADoctor__content'>
        <h1>Find a Family Doctor</h1>
        <p>If you are one of the 2.2 million Ontarians living life without a family doctor, we have put together a list of resources to help you find one. We understand this process remains difficult, and this is why the Ontario government needs to take urgent action to ensure every patient has access to a family doctor. </p>
      </div>
      <div className='findADoctor__image'>
        <img src={image} />
      </div>
    </div>
    </>
  );
};