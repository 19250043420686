import * as React from 'react';
import { Button } from '../element/Button';
import image from "../../images/about/1633231@2x.png";
import "./TakeAction.scss";

type props = {
  flag: string;
}

export const TakeAction = ({flag}: props) => {
  return (
    <div className={`takeAction takeAction${flag}`}>
      {/* <div className='takeAction__background'> */}
      {/* <div className='takeAction__image'>
        <img src={image}/>
      </div> */}
        <div className='takeAction__content'>
          <h3>Help us ensure the government knows that every Ontarian deserves a life with a family doctor by sharing our campaign on social media.</h3>
          {/* <p>If you, like many other Ontarians, are still unable to find a family doctor, you may want to urge your local Member of Provincial Parliament to do more about the situation. It’s important we bring this urgent issue to the attention of the Ontario government. </p>
          <p>Want to find out how you can help?</p> */}
          {/* <Button text={'Find out how'} flag={['button__call-to-action']} isNav={true} handleClick={"onepager.pdf"} isNewPage={true} /> */}
        </div>
      {/* </div> */}
    </div>
  );
};