import * as React from 'react';
import image from "../../images/findDoc/3460325-4@2x.png";
import "./HealthCareConnect.scss";

export const HealthCareConnect = () => {
  return (
    <div className='healthCareConnect'>
      <div className='healthCareConnect__image'>
        <img src={image} />
      </div>
      <div className='healthCareConnect__content'>
        <h2>Health Care Connect</h2>
        <p>Health Care Connect refers Ontarians who don't have a physician to a family healthcare provider who may be accepting new patients.</p>
        <p>Patients with no family doctor can call or go online to <a href="http://www.ontario.ca/healthcareconnect" target="_blank">www.ontario.ca/healthcareconnect</a> to register with the program. To successfully register, unattached patients must have a valid OHIP card and complete a health questionnaire to determine their need for family health care services. Priority is given to individuals who have greater health needs.</p>
        <p>Once registered, program participants are assigned to a Care Connector. These are nurses employed by Home and Community Care Support Services Organizations, who will work with family physicians and nurse practitioners that are accepting new patients.</p>
        <p>Once an available doctor or nurse is found, Health Care Connect will give the patient the provider's practice information to schedule their first appointment.</p>
        <p>For more information on how to apply, please visit <br/> <a href="http://www.ontario.ca/healthcareconnect" target="_blank">www.ontario.ca/healthcareconnect</a></p>
        {/* <p>Help us ensure the government knows that every Ontarian deserves a life with a family doctor by sharing our campaign on social media. </p> */}
      </div>
    </div>
  );
};